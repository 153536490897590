import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    organization: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.send(
      'service_gnbg9nj', // Replace with your EmailJS service ID
      'template_00fu31d', // Replace with your EmailJS template ID
      {
        'from_name': formData.name,
        'message': 'I am from the organization ' + formData.organization + "\n" + formData.message,
        'to_name': 'Learnathon Team',
        'reply_to': formData.email
      },
      'dEHH5ztdmRN60Mvt-' // Replace with your EmailJS user ID
    ).then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      alert('Email sent successfully!');
    }).catch((error) => {
      console.error('FAILED...', error);
      alert('Failed to send email.');
    });
  };

  return (
    <div id="contactus" className="w-fullflex flex-col my-20">
      <h2 className="text-center text-[#1a365d] text-3xl md:text-4xl font-bold font-montserrat mb-6">Contact Us</h2>
      <form onSubmit={handleSubmit} className="flex flex-col bg-white rounded-lg border border-gray-200 p-6 space-y-6 md:mx-20 mx-5">
        <div className="flex inline-flex justify-center items-center gap-5">
          <label htmlFor="name" className="w-1/5 block text-start text-gray-700 text-sm font-semibold font-openSans">Name</label>
          <input
            type="text"
            id="name"
            value={formData.name}
            onChange={handleChange}
            className="w-4/5 px-3 py-2 border border-gray-300 rounded-md text-gray-700 focus:outline-none focus:border-[#1a365d]"
            placeholder="Your name"
          />
        </div>
        <div className="flex inline-flex justify-center items-center gap-5">
          <label htmlFor="organization" className="w-1/5 block text-start text-gray-700 text-sm font-semibold font-openSans">College / Organisation Name</label>
          <input
            type="text"
            id="organization"
            value={formData.organization}
            onChange={handleChange}
            className="w-4/5 px-3 py-2 border border-gray-300 rounded-md text-gray-700 focus:outline-none focus:border-[#1a365d]"
            placeholder="Your college or organization"
          />
        </div>
        <div className="flex inline-flex justify-center items-center gap-5">
          <label htmlFor="email" className="w-1/5 block text-start text-gray-700 text-sm font-semibold font-openSans">Email</label>
          <input
            type="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            className="w-4/5 px-3 py-2 border border-gray-300 rounded-md text-gray-700 focus:outline-none focus:border-[#1a365d]"
            placeholder="Your email"
          />
        </div>
        <div className="flex inline-flex justify-center items-center gap-5">
          <label htmlFor="message" className="w-1/5 block text-start text-gray-700 text-sm font-semibold font-openSans">Message</label>
          <textarea
            id="message"
            rows="4"
            value={formData.message}
            onChange={handleChange}
            className="w-4/5 px-3 py-2 border border-gray-300 rounded-md text-gray-700 focus:outline-none focus:border-[#1a365d]"
            placeholder="Your message"
          ></textarea>
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            className="px-6 py-2 bg-[#1a365d] text-white font-semibold font-montserrat rounded-md hover:bg-[#2c4a7c] focus:outline-none focus:ring-2 focus:ring-[#1a365d] focus:ring-opacity-50 transition duration-300"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactPage;