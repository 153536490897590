// Layout.js
import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "./navBar/navBar";
import Footer from "./footer/footer";
import RegistrationPage from "../pages/registrationPage/registrationPage";

function Layout(props) {

  return (
    <div className="overflow-hidden">
      <header>
        <div className="fixed top-0 z-50" >
          <NavBar
            setModalIsOpen={props.setModalIsOpen}
            isRegistrationOpen={props.isRegistrationOpen}
          />
        </div>
      </header>
        {
          props.modalIsOpen &&
          <div >
            <div
              onClick={() => props.setModalIsOpen(false)}
              className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50"></div>
            <div className="fixed inset-0 bg-white rounded-3xl p-6 relative z-50 m-10" >
              <i
                className="fas fa-close absolute top-[5%] right-[5%] w-[50px]  text-grey-500 cursor-pointer"
                onClick={() => props.setModalIsOpen(false)}
              ></i>
              < RegistrationPage
                setModalIsOpen={props.setModalIsOpen}
              />
            </div>
          </div>
        }
      <main >
        <Outlet />
      </main>
      <footer className="bg-[#E2E8F0]">
        <Footer />
      </footer>
    </div>
  );
}

export default Layout;
