import React from 'react';
import { Facebook, Twitter, Instagram, Linkedin } from 'lucide-react';
import LogoPoweredBy from '../../assets/images/logo-poweredby.svg';
import ILVLogo from '../../assets/images/ILV-logo.png';
import scrollToSection from '../../helpers/scrollToSection';

const Footer = () => {
  return (
    <footer className="w-full mx-auto px-4 py-8 space-y-8" >
      <div className="flex flex-col md:flex-row justify-between items-center gap-8">
        <div className="flex flex-col sm:flex-row items-center gap-6">
            <img
                className="h-13 w-auto"
                src={LogoPoweredBy} 
                alt="Learnathon 2024"
                onClick={() => scrollToSection('home')} 
                style={{cursor: 'pointer', fit: 'contain'}}
            />
          <img className="w-20 h-20 sm:w-24 sm:h-24 rounded-full" src={ILVLogo} alt="Company logo" />
        </div>
        
        <nav className="flex flex-wrap justify-center gap-4 sm:gap-5">
          {['Home', 'About', 'Details', 'Partners', 'Contact Us'].map((item, index) => (
            <div key={index} onClick={() => scrollToSection(item.toLowerCase())} className="cursor-pointer text-black text-sm sm:text-base font-bold font-['Open Sans'] hover:text-[#1a365d]">
              {item}
            </div>
          ))}
        </nav>
        
        <div className="flex justify-center items-center gap-6 sm:gap-10">
          <a href="https://www.facebook.com/profile.php?id=61566443798444&mibextid=LQQJ4d">
            <Facebook className="w-6 h-6 text-[#1a365d] cursor-pointer hover:text-[#2c7a7b]" />
          </a>
          <a href="https://www.instagram.com/learnathon_by_goalstreet?igsh=MXJkZnk4OGRnNng3ZQ%3D%3D&utm_source=qr">
            <Instagram className="w-6 h-6 text-[#1a365d] cursor-pointer hover:text-[#2c7a7b]" />
          </a>
          <Linkedin className="w-6 h-6 text-[#1a365d] cursor-pointer hover:text-[#2c7a7b]" />
        </div>
      </div>
      <div className="text-center text-black text-sm sm:text-base font-['Open Sans']">
        2024{" "}<span className="font-bold">©</span>{" "}learnathon.live - All Rights Reserved
      </div>
    </footer>
  );
};

export default Footer;