import React, { useState } from 'react';
import HomePage from '../homePage/homePage';
import AboutPage from '../aboutPage/aboutPage';
import DetailsPage from '../detailsPage/detailsPage';
import PartnersPage from '../partnersPage/partnersPage';
import ContactPage from '../contactPage/contactPage';
const LandingPage = (props) => {
  return (
    <div className="landing-page  bg-[#E2E8F0]">
      < HomePage
        setModalIsOpen={props.setModalIsOpen}
        isRegistrationOpen={props.isRegistrationOpen}
      />
      < AboutPage />
      < DetailsPage
        setModalIsOpen={props.setModalIsOpen}
        isRegistrationOpen={props.isRegistrationOpen}
        setIsRegistrationOpen={props.setIsRegistrationOpen}
      />
      < PartnersPage />
      < ContactPage setModalIsOpen={props.setModalIsOpen}
      />
    </div>
  );
};

export default LandingPage;
