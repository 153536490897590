import React, { useState, useEffect } from 'react';

const Timer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = new Date(targetDate) - new Date();
    return difference > 0 ? Math.floor(difference / 1000) : 0;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timerId = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timerId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetDate]);

  const formatTime = (seconds) => {
    const days = Math.floor(seconds / (3600 * 24));
    const hrs = String(Math.floor((seconds % (3600 * 24)) / 3600)).padStart(2, '0');
    const mins = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
    const secs = String(seconds % 60).padStart(2, '0');
    
    return { days, hrs, mins, secs };
  };

  const { days, hrs, mins, secs } = formatTime(timeLeft);

  return (
    <div className="text-center text-[#E2E8F0] text-4xl sm:text-6xl md:text-8xl font-bold tracking-widest">
      {days > 0 ? `${days}d ${hrs}hrs ${mins}m ${secs}s` : `${hrs}:${mins}:${secs}`}
      <div className="flex gap-14 justify-center mt-4">
        {days > 0 && (
          <div className="w-full text-white text-sm sm:text-lg md:text-xl font-semibold uppercase">
            Days
          </div>
        )}
        <div className="w-full text-white text-sm sm:text-lg md:text-xl font-semibold uppercase">
          Hours
        </div>
        <div className="w-full text-white text-sm sm:text-lg md:text-xl font-semibold uppercase">
          Minutes
        </div>
        <div className="w-full text-white text-sm sm:text-lg md:text-xl font-semibold uppercase">
          Seconds
        </div>
      </div>
    </div>
  );
};

export default Timer;