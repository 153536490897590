import React, { useState, useEffect } from 'react';
import { Menu, X } from 'lucide-react';
import logo from '../../assets/images/logo.svg';

const NavBar = (props) => {
  const [activeSection, setActiveSection] = useState('home');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const sections = ['home', 'about', 'workshop', 'hackathon', 'details', 'contactus'];
      const currentSection = sections.find(section => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          return rect.top <= 100 && rect.bottom >= 100;
        }
        return false;
      });
      if (currentSection) {
        setActiveSection(currentSection);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setIsMenuOpen(false);
  };

  return (
    <nav className="w-screen bg-[#E2E8F0] transition duration-300 ease-in-out">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center h-24">
          <div className="flex-shrink-0">
            <img 
              className="h-12 w-auto " 
              src={logo} 
              alt="Learnathon 2024"
              onClick={() => {
                if(props.isUpload){
                  window.location.href = '/';
                } else {
                  scrollToSection('/home');
                }
              }} 
              style={{cursor: 'pointer'}} 
            />
          </div>
          {!props.isUpload && 
          <div className="hidden md:flex md:items-center md:space-x-8">
            {['Home', 'About', 'Workshop', 'Hackathon', 'Contact Us'].map((item) => (
              <button
                key={item}
                onClick={() => scrollToSection(item.toLowerCase().replace(' ', ''))}
                className={`text-gray-600 hover:text-gray-900 md:px-1 px-3 py-2 text-sm font-medium relative ${
                  (activeSection === item.toLowerCase().replace(' ', '')) ? 'text-gray-900' : ''
                }`}
              >
                {item}
                {(activeSection === item.toLowerCase().replace(' ', '')) && (
                  <span className="absolute bottom-0 left-0 w-full h-0.5 bg-gray-900"></span>
                )}
              </button>
            ))}
            <button
              onClick={() => {
                console.log('Register button clicked');
                if (props.isRegistrationOpen) {
                  props.setIsMenuOpen(true);
                }
                else {
                  alert('Registration are not yet open');
                }
               }}
              className="text-[#1A365D] hover:text-white text-sm font-medium border-2 border-[#1A365D] hover:bg-[#1A365D] rounded py-2 px-4">
              Register
            </button>
          </div>}
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-600 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#1A365D]"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" aria-hidden="true" />
              ) : (
                <Menu className="h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>
      {/* Mobile menu, show/hide based on menu state */}
      {isMenuOpen && (
        <div className="md:hidden bg-white shadow-lg"> {/* Added background color and shadow */}
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {['Home', 'About', 'Details', 'Contact Us'].map((item) => (
              <button
                key={item}
                onClick={() => scrollToSection(item.toLowerCase().replace(' ', ''))}
                className={`block px-3 py-2 text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 w-full text-left ${
                  (activeSection === item.toLowerCase().replace(' ', '')) ? 'text-gray-900 bg-gray-50' : ''
                }`}
              >
                {item}
              </button>
            ))}
            <button
              onClick={() => {
                console.log('Register button clicked');
                if (props.isRegistrationOpen) {
                  props.setIsMenuOpen(true);
                }
                else {
                  alert('Registration are not yet open');
                }
              }}
              className="block w-full text-left px-3 py-2 text-base font-medium text-[#1A365D] hover:text-white border-2 border-[#1A365D] hover:bg-[#1A365D] rounded"
            >
              Register
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavBar;