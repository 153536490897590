import React from 'react';
import heroBackground from '../../assets/images/hero-bg.svg';
import homePageImage from '../../assets/images/homePageImage.svg';
import scrollToSection from '../../helpers/scrollToSection';
import ILVLogo from '../../assets/images/ILV-logo.png';
const HomePage = (props) => {
  return (
    <section id="home">
      <div className="flex flex-col h-screen justify-end md:flex-row overflow-hidden">
        {/* Left Side - Text Content */}
        <div className="w-full md:w-1/2 flex flex-col justify-end items-start p-2 md:p-8 lg:pt-20 lg:p-12 z-10">
          <div className="flex flex-col justify-start items-start gap-10 w-full">
            <div className="flex flex-col justify-start items-start gap-3 md:gap-4 lg:gap-6">
              <div className="flex flex-col justify-start items-start gap-1 md:gap-2 lg:gap-4">
                <div className="flex flex-row items-center justify-start space-x-1 text-[#4A5568] text-lg sm:text-xl md:text-2xl font-medium font-montserrat">
                  < img className='h-[100px]' src={ILVLogo} alt ="I ❤️ HR" ></img>
                  presents
                </div>
                <div className="text-[#4A5568] text-2xl sm:text-3xl md:text-4xl lg:text-6xl font-bold font-montserrat">
                  Learnathon
                </div>
                <div className="text-[#4A5568] text-base sm:text-lg md:text-lg text-start font-normal font-montserrat">
                  Learn to Hack - Hack to Learn
                </div>
                <div className="text-[#4A5568] text-base sm:text-lg md:text-xl text-start font-bold font-montserrat">
                  Artificial Intelligence & Machine Learning
                </div>
              </div>
              < div className='grid grid-cols-2 gap-6 text-start' >
                <div className="flex flex-col justify-start items-start gap-1">
                  <span className="text-[#4A5568] text-base sm:text-lg md:text-3xl font-semibold font-montserrat">
                    25
                  </span>
                  <span className="text-[#4A5568] text-sm font-montserrat">
                    UNIVERSITIES
                  </span>
                </div>
                <div className="flex flex-col justify-start items-start gap-1">
                  <span className="text-[#4A5568] text-base sm:text-lg md:text-3xl font-semibold font-montserrat">
                    100
                  </span>
                  <span className="text-[#4A5568] text-sm font-montserrat">
                    COLLEGES
                  </span>
                </div>
                <div className="flex flex-col justify-start items-start gap-1">
                  <span className="text-[#4A5568] text-base sm:text-lg md:text-3xl font-semibold font-montserrat">
                    100+
                  </span>
                  <span className="text-[#4A5568] text-sm font-montserrat">
                    Industry Experts
                  </span>
                </div>
                <div className="flex flex-col justify-start items-start gap-1">
                  <span className="text-[#4A5568] text-base sm:text-lg md:text-3xl font-semibold font-montserrat">
                    12 {" "}
                    < span className="text-[#4A5568] text-base font-montserrat">
                      Hrs
                    </span>
                  </span>
                  <span className="text-[#4A5568] text-sm font-montserrat">
                    Hackathon ( AI & ML )
                  </span>
                </div>
                <div className="flex flex-col justify-start items-start gap-1">
                  <span className="text-[#4A5568] text-base sm:text-lg md:text-3xl font-semibold font-montserrat">
                    50,000
                  </span>
                  <span className="text-[#4A5568] text-sm font-montserrat">
                    Participants
                  </span>
                </div>
                <div className="flex flex-col justify-start items-start gap-1">
                  <span className="text-[#4A5568] text-base sm:text-lg md:text-3xl font-semibold font-montserrat">
                    ₹ 2.5 { " " }
                    < span className="text-[#4A5568] text-base font-montserrat">
                      million
                    </span>
                  </span>
                  <span className="text-[#4A5568] text-sm font-montserrat">
                    Cash Prizes (worth)
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-start items-start gap-3 md:gap-2 lg:gap-2 w-full sm:w-auto">
              <button
                onClick={() => {
                  if (props.isRegistrationOpen) {
                    props.setIsMenuOpen(true);
                  }
                  else {
                    alert('Registration are not yet open');
                  }
                }}
                className="w-full sm:w-auto px-4 py-2 sm:px-6 md:px-8 lg:px-12 md:py-3 bg-[#1A365D] shadow-[0px_0px_4px_rgba(0,0,0,0.25)] rounded-lg md:rounded-xl">
                <span className="text-white text-sm sm:text-base font-semibold font-montserrat">
                  Register Now
                </span>
              </button>
              <button
                className="w-full sm:w-auto px-4 py-2 sm:px-6 md:px-8 lg:px-12 md:py-3 bg-white shadow-[0px_0px_4px_rgba(0,0,0,0.25)] rounded-lg md:rounded-xl"
                onClick={() => scrollToSection('contactus')}
              >
                <span className="text-[#1A365D] text-sm sm:text-base font-semibold font-montserrat">
                  Contact Us
                </span>
              </button>
            </div>
          </div>
        </div>

        {/* Right Side - Images */}
        <div className="hidden lg:block md:block w-4/5 h-100 relative mt-6 md:mt-0 overflow-visible" style={{ backgroundImage: `url(${heroBackground})`, backgroundRepeat: 'no-repeat' }}>
          {/* Hero Avatar (hidden on mobile screens, visible from md and above) */}
          <img
            src={homePageImage}
            alt="Hero Avatar"
            className=" md:block absolute top-[35%] right-[20%] w-3/5 h-auto object-contain"
          />
        </div>
      </div>
    </section>
  );
};

export default HomePage;