import React, { useEffect, useState } from 'react';
import { doc, getDoc } from "firebase/firestore";
import ProgressBar from '../../components/progressBar';
import Timer from '../../components/timer';
import bgComponent from '../../assets/images/bg-components.png';
import scrollToSection from '../../helpers/scrollToSection';
import db from "../../firebase";

const ProcessDetails = (props) => {
  const [events, setEvents] = useState([]);

  const parseFirebaseTimestamp = (timestamp) => {
    if (!timestamp || !timestamp.seconds) return new Date();
    return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
  };

  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  const formatTime = (date) => {
    return date.toTimeString().split(" ")[0].substr(0, 5);
  };
  
  const formatMonthDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "short" };
    const month = date.toLocaleDateString("en-US", options);
    const day = date.getDate();
    const daySuffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
      }
    };
    return `${month} ${day}${daySuffix(day)}`;
  };
  
  useEffect(() => {
    const eventOrder = [
      "College Registration",
      "Learnathon",
      "Zonal Hackathons",
      "Final Hackathon (State Level)",
    ];

    const initializeEvents = (data) => {
      return eventOrder.map(name => {
        const timestamp = data[name] || { seconds: 0, nanoseconds: 0 };
        const date = parseFirebaseTimestamp(timestamp);
        return {
          name,
          date: formatDate(date),
          time: formatTime(date),
        };
      });
    };

    const getEvents = async () => {
      const docRef = doc(db, "events", "learnathon-2024");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        const data = initializeEvents(docSnap.data());
        if (new Date(data[0].date) > new Date()) {
          props.setIsRegistrationOpen(false);
        } else {
          props.setIsRegistrationOpen(true);
        }
        setEvents(data);
      } else {
        console.log("Failed to fetch data");
      }
    };
    getEvents();
  }, []);

  const findNextFutureEventIndex = () => {
    const now = new Date();
    return events.findIndex(event => new Date(event.date) > now);
  };

  return (
    <div className="bg-gradient-to-b from-black via-[#1A365D] to-[#42C8C9]">
      <div 
        className="flex flex-col justify-start items-center gap-20 p-20 bg-cover bg-center bg-no-repeat h-max w-full" 
        style={{ backgroundImage: `url(${bgComponent})` }}
      >
        <div className="text-center text-[#E2E8F0] text-lg sm:text-2xl md:text-4xl font-normal leading-tight">
          Bringing together the brightest minds from{" "}
          <span className="font-bold">Telangana </span>and
          <span className="font-bold"> Andhra Pradesh</span>
        </div>
        <div className="flex flex-col items-center gap-4">
          {events.length > 0 && new Date(events[0].date) > new Date() ? (
            <Timer targetDate={events.find(event => event.name === "College Registration")?.date} />
          ) :
            <div className="flex flex-col items-center gap-6">
              <button
                onClick={() => {
                  props.setModalIsOpen(true);
                  scrollToSection('home');
                }}
                className="px-12 py-3 shadow-md rounded-md border border-white text-white text-lg font-semibold"
              >
                Register your college
              </button>
              <div className="text-white text-sm font-semibold">
                Scroll to know more
              </div>
            </div>
          }
          
          
        </div>
        <div className="md:w-max w-full bg-white rounded-lg flex flex-col items-center gap-4 py-6 px-8">
          <ProgressBar steps={events.length} activeStep={findNextFutureEventIndex()} activeColor="bg-gradient-to-b from-[#1A365D] to-[#2C7A7B]" />
          <div className="flex justify-center items-start gap-8">
            {events.map((event, index) => (
              <div
                key={index}
                className="flex flex-col items-start gap-2.5 text-start w-full"
              >
                <div className="text-sm text-gray-600 w-full">
                  {event.name}
                </div>
                <div className="flex items-center space-x-4">
                  <p className="text-sm text-gray-600 font-bold">
                    {index === 1 &&  formatMonthDate(events.find(event => event.name === "College Registration")?.date) + "-"}  {formatMonthDate(event.date)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessDetails;