import React from "react";

import WorkShopDetails from "./workShopDetails";
import HackathonDetails from "./hackathonDetails";
import ProcessDetails from "./processDetails";
import CollegeRegistrationDetails from "./collegeRegistrationDetails";
import RewardsDetails from "./rewardDetails";

const DetailsPage = (props) => {
  return (
    <section id="details" className="h-max">
      < WorkShopDetails />
      < HackathonDetails />
      < ProcessDetails
        setModalIsOpen={props.setModalIsOpen}  
        isRegistrationOpen={props.isRegistrationOpen}
        setIsRegistrationOpen={props.setIsRegistrationOpen}
      />
      < CollegeRegistrationDetails
        setModalIsOpen={props.setModalIsOpen}
        isRegistrationOpen={props.isRegistrationOpen}
      />
      < RewardsDetails />
    </section>
  );
};

export default DetailsPage;
