import React from "react";
import certificateImage from "../../assets/images/certificate.svg";
import careerGuidance from "../../assets/images/career-guidance.svg";
import internshipImage from "../../assets/images/internship.svg";
import certificationImage from "../../assets/images/certification.svg";
import assessmentImage from "../../assets/images/assessment.svg";
import placementImage from "../../assets/images/placement.png";
import bgComponent from "../../assets/images/bg-components.png";

const RewardDetails = () => {
  return (
    <div className="h-max w-full bg-gradient-to-b from-black via-[#1A365D] to-[#3771C3]">
      <div
        className="w-full relative flex flex-col justify-center items-center bg-cover bg-center pb-10"
        style={{ backgroundImage: `url(${bgComponent})` }}
      >
        <div className="w-full md:px-10 pt-10 flex flex-wrap md:flex-row items-center justify-evenly">
          <div className="flex flex-col items-center gap-4 text-center md:text-left">
            <img
              className="h-32 md:h-48"
              src={certificateImage}
              alt="Winner/Participation"
            />
            <div className="text-white text-lg md:text-2xl font-serif italic font-extrabold">
              Winner/Participation
              <br />
              Certificate
            </div>
          </div>
          <div className="flex flex-col items-center gap-4 text-center md:text-left">
            <img
              className="h-32 md:h-48"
              src={internshipImage}
              alt="Internship"
            />
            <div className="text-white text-lg md:text-2xl font-serif italic font-extrabold">
              Internship Opportunity
            </div>
          </div>
          <div className="flex flex-col items-center gap-4 text-center md:text-left">
            <img
              className="h-32 md:h-48 object-cover object-contain"
              src={careerGuidance}
              alt="Career"
            />
            <div className="text-white text-lg md:text-2xl font-serif italic font-extrabold">
              Career Guidance
            </div>
          </div>
        </div>
        <div className="text-white text-opacity-10 text-[8vw] md:text-[10vw] font-serif italic font-extrabold text-center">
          Rewards
        </div>
        <div className="w-full md:px-10 pb-10 flex flex-wrap md:flex-row items-center justify-evenly gap-6 md:gap-12">
          <div className="flex flex-col items-center gap-4 text-center md:text-left">
            <img
              className="h-32 md:h-48"
              src={certificationImage}
              alt="Certification"
            />
            <div className="text-white text-lg md:text-2xl font-serif italic font-extrabold">
              Certifications
            </div>
          </div>
          <div className="flex flex-col items-center gap-4 text-center md:text-left">
            <img
              className="h-32 md:h-48"
              src={assessmentImage}
              alt="Assessment"
            />
            <div className="text-white text-lg md:text-2xl font-serif italic font-extrabold">
              Assessments
            </div>
          </div>
          <div className="flex flex-col items-center gap-4 text-center md:text-left">
            <img
              className="h-32 md:h-48 object-cover object-contain"
              src={placementImage}
              alt="Placement"
            />
            <div className="text-white text-lg md:text-2xl font-serif italic font-extrabold">
              Placement assistance
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardDetails;
