import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/landingPage/landingPage";
import "./App.css";
import Layout from "./layouts/layout";
import DocumentUploadPage from "./pages/documentUploadPage/documentUploadPage";
import AdminLayout from "./layouts/admin/admin";
import Dashboard from "./pages/adminDashboard/adminDashboard";
import AdminPage from "./pages/adminPage/adminPage";

function App() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [adminPage, setAdminPage] = useState(Dashboard);
  const [isRegistrationOpen, setIsRegistrationOpen] = useState(false);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <Layout
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
                isRegistrationOpen={isRegistrationOpen}
              />
            }
          >
            <Route
              index
              element={
                <LandingPage
                  setModalIsOpen={setModalIsOpen}
                  isRegistrationOpen={isRegistrationOpen}
                  setIsRegistrationOpen={setIsRegistrationOpen}
                />
              }
            />
          </Route>
          <Route path="/register/:email" element={<DocumentUploadPage />} />
          <Route
            path="/admin"
            element={
              <AdminLayout adminPage={adminPage} setAdminPage={setAdminPage} />
            }
          >
            <Route index element={<AdminPage page={adminPage} />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
