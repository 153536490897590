import React from 'react';
import collegeImage from "../../assets/images/register-college.svg";
import bgComponent from "../../assets/images/bg-components.png";
import scrollToSection from '../../helpers/scrollToSection';

const CollegeRegistrationDetails = (props) => {
    return (
      <div className="bg-gradient-to-b from-[#42C8C9] via-[#1A365D] to-black h-max">
        <div className="flex flex-row justify-center items-center md:p-20 " style={{ backgroundImage: `url(${bgComponent})` }}>
          <img
            src={collegeImage}
            alt="Register College"
            className="w-3/6 object-contain"
          />
          <div className="w-3/6 flex flex-col justify-start items-start gap-8" >
            <div className="text-[#1A365D] text-lg sm:text-3xl font-bold">
              Register Your College
            </div>
            <div className="text-white text-lg sm:text-2xl font-normal">
              How participating helps you?
            </div>
            <div className="flex flex-col justify-start text-white text-base sm:text-xl text-start">
                <li>Participants will be receiving training on the technology by experts.</li>
                <li>Participants will be receiving an internship opportunity.</li>
                <li>Participants will receive guidance for their career.</li>
                <li>Inculcate competitive spirit and learning thrive among students.</li>
                <li>Participants will receive attractive rewards and cash prize.</li>
            </div>
            {/* <div className="border border-[#42C8C9] rounded-lg p-4 text-center">
              <div className="text-[#42C8C9] text-base sm:text-lg font-semibold">
                Join your college in the learnathon <br />
                with participation of at-least 200 participants
              </div>
            </div> */}
            <button
              onClick={() => {
                if (props.isRegistrationOpen) {
                  props.setModalIsOpen(true);  
                  scrollToSection('home');
                }
                else {
                  alert('Registration are not yet open');
                }
              }}
              className="px-12 py-3 bg-[#42C8C9] shadow-md rounded-md text-[#1A365D] text-lg font-semibold">
              Register
            </button>
          </div>
        </div>
      </div>
    );
};

export default CollegeRegistrationDetails;